import React from "react";
import ReactModal from "react-modal";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container'


class IndCand3 extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      selectedCandidate: -1,
      modalSection: "home"
    };

    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);

    this.handleModelSection = this.handleModalSection.bind(this);
  
  }

  componentDidUpdate(prevProps) {

    if (this.props!== prevProps) {
      this.setState ({
        selectedCandidate: -1
      })
    }
    
  }
  
  handleModalSection (section) {
    console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!" + section);
    this.setState({ 
                    modalSection: section
                  });
  }

  handleOpenModal (id) {
    this.setState({ 
                    showModal: true,
                    selectedCandidate: id
                  });
  }
  
  handleCloseModal () {
    this.setState({
                    showModal: false,
                    selectedCandidate: -1,
                    modalSection: "home"
                  });
  }
  

    render() {
      return (
        <Container className="candidates3-pagewrap">
        <Row>
        <Col className="candidate3-boxes-wrapper" md={4}>
        <ul>
        {
          this.props.dataD.map((candidate)  => {
            return (
              <li className={candidate.id === this.state.selectedCandidate.id ? "candidate3-box candidate3-box-selected" : "candidate3-box"}>
                  <h3>{candidate.first_name} {candidate.last_name}</h3>
                  <p>{candidate.party}</p>
                  <p>{candidate.constituency}</p>
                  <button className="candidates3_btn float-end" onClick={() => this.handleOpenModal(candidate)}>View</button>
              </li>
              )
        })
        }
        </ul>
        </Col>
        <Col md={8}>
        {this.state.selectedCandidate != -1 && (
        
                  <div class= "cand-info-area">
                     <h1>{this.state.selectedCandidate.first_name} {this.state.selectedCandidate.last_name}</h1>
                     <h3>{this.state.selectedCandidate.party} candidate for {this.state.selectedCandidate.constituency}</h3>
                     <br/>
                     
                     { this.state.selectedCandidate.sections.map((section) => {
                                                    

                                                    return  <div>
                                                            <h3>{section.section_name_pretty}</h3>
                                                            <Container>
                                                            
                                                              {section.questions.map((question) => {
                                                                
                                                                            return <Row>
                                                                                        <Col sm={8} className="modal-col col-question">{question.question_pretty_name}</Col>
                                                                                        <Col sm={4} className="modal-col">{question.question_answer}</Col>
                                                                                    </Row>
                                                              })}

                                                              {section.questions.map((question) => {
                                                                
                                                                            return <Row>
                                                                                        <Col sm={8} className="modal-col col-question">{question.question_pretty_name}</Col>
                                                                                        <Col sm={4} className="modal-col">{question.question_answer}</Col>
                                                                                    </Row>
                                                                })}
                                                          </Container>
                                                          <br/><br/>
                                                          </div>
                                                    
                     })}

                    <button className="candidates_btn" onClick={this.handleCloseModal}>Close</button>
                  </div>
        
     
      )}
        {this.state.selectedCandidate != -1 && (
        
            <ReactModal 
                    isOpen={this.state.showModal}
                    contentLabel="Minimal Modal Example"
                    className="candidate-modal-mobile"
                    overlayClassName="overlay-mobile"
                    onRequestClose={this.handleCloseModal}
                  >
                  
                    <div>
                     <h1>{this.state.selectedCandidate.first_name} {this.state.selectedCandidate.last_name}</h1>
                     <h3>{this.state.selectedCandidate.party} candidate for {this.state.selectedCandidate.constituency}</h3>
                     <br/>
                        {this.state.modalSection === "home" && (
                            <div className="section_buttons">
                              { this.state.selectedCandidate.sections.map((section) => {
                                                              

                                                              return  <div>
                                                                      <button className="section_btn" onClick={() => this.handleModelSection(section)}>{section.section_name_pretty}</button>
                                                                      </div>
                                                              
                              })}
                            </div>
                          
                        )}
                        {this.state.modalSection !== "home" && (
                            <div>
                                                             <div>
                                                              <h3>{this.state.modalSection.section_name_pretty}</h3>
                                                              <Container>
                                                              
                                                                {this.state.modalSection.questions.map((question) => {
                                                                  
                                                                              return <Row>
                                                                                          <Col sm={8} className="modal-col col-question">{question.question_pretty_name}</Col>
                                                                                          <Col sm={4} className="modal-col">{question.question_answer}</Col>
                                                                                      </Row>
                                                                })}
                                                            </Container>
                                                            <br/><br/>
                                                            </div>
                            </div>
                        )}
                          
                    </div>
                    <button className="candidates_btn" onClick={this.handleCloseModal}>Close</button>
              </ReactModal>


)}

      </Col>
      </Row>
      </Container>
      )
      }

}

export default IndCand3


