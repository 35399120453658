import { StaticQuery } from "gatsby";
import { filter } from "lodash";
import React, { useState, useEffect } from "react";
import JSONData from "../../../content/assets/candidates.json"
import IndCand3 from "./indcandidates3";


const CanComp3Individual= () => {

    const [search_term, SetSearchTerm] = useState("")
    const [party_term, SetPartyTerm] = useState("All")
    const [constituency_term, SetConstituencyTerm] = useState("All")

    const result = JSONData.candidates;


    const SetKeyword = (event) => {
      var searchWord = event.target.value
      SetSearchTerm(searchWord)
      GetFilteredResult();
    }

    const SetParty = (event) => {
      var party = event.target.value
      SetPartyTerm(party)
      GetFilteredResult();
    }

    const SetConstituency = (event) => {
      var constituency = event.target.value
      SetConstituencyTerm(constituency)
      GetFilteredResult();
    }

    const GetFilteredResult = () => {
      var newFilter;
      if(search_term != "")
      {
          newFilter = result.filter((value) => {
          var name = value.first_name.toLowerCase() + " " +  value.last_name.toLowerCase()
          return name.includes(search_term.toLowerCase());
        });
      }
      else {
        newFilter = result;
      }

      if(party_term != "All")
      {
          newFilter = newFilter.filter((value) => {
          var party_lowercase = value.party.toLowerCase()
          return party_lowercase.includes(party_term.toLowerCase());
        });
      }

      if(constituency_term != "All")
      {
          newFilter = newFilter.filter((value) => {
          var constituency_lowercase = value.constituency.toLowerCase()
          return constituency_lowercase.includes(constituency_term.toLowerCase());
        });
      }

      return newFilter

    }

  return <div>
           <div class="candidate-search">
              Search Candidates:  <input type="text" placeholder="Search.." name="search" onChange={SetKeyword}/>
              Filter By:  
              <label for="constituency">By Constituency </label>
                <select id="constituency"onChange={SetConstituency}>
                    <option>All</option>
                    <option>Belfast South</option>
                    <option>Belfast North</option>
                    <option>Belfast East</option>
                </select>

            <label for="party">By Party </label>
                <select id="party"  onChange={SetParty}>
                    <option>All</option>
                    <option>Alliance</option>
                    <option>DUP</option>
                    <option>Sinn Fein</option>
                </select>
           </div>
            <div>
              <IndCand3 dataD={ GetFilteredResult()}></IndCand3>
            </div>
          </div>

  
}

export default CanComp3Individual
