import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { useState } from 'react'
import CanComp3Individual from "../components/candidates3/candidates3componentindividual"
import logo from '../../content/assets/logo.png';



class candidateslanding extends React.Component {
  constructor({ data, location }) {
    
    super({ data, location });
    this.state = {
      mainFilter: "constituency"
    }
    
    this.siteTitle = data.site.siteMetadata.title;
    this.location = location;
  }

  handlefilterMain = e => {
    const value = e.target.value;

    this.setState({
      mainFilter: value
    });
  };
  

  render() {
    return (
      <div class="candidateslanding-wrapper">
        <div class="container-fluid">
          
          <div class="candidateslanding-content">
            <img
                src={logo}
                height="400em"
                className="d-inline-block align-top logo"
                alt="Progressive Politics NI logo"
              />
            <br/>
            <label for="landing_party"><h3>SHOW ME</h3></label>
            <select id="landing_party" class="landing-select">
              <option>All</option>
              <option>Alliance</option>
              <option>DUP</option>
              <option>Sinn Fein</option>
            </select>
            <br/>
            <label for="landing_constituency"><h3>IN</h3></label>
            <select id="landing_constituency" class="landing-select">
              <option>All</option>
              <option>Belfast South</option>
              <option>Belfast North</option>
              <option>Belfast East</option>
            </select>
            <br/>
            <button class="landing_btn"><h3>GO</h3></button>

          </div>
        </div>
      </div>
    //   <Layout location={this.location} title={this.siteTitle}>
    //   <Seo title="candidateslanding" />
      
    //   <CanComp3Individual></CanComp3Individual>
     
    // </Layout>
    );
  }
}

export default candidateslanding

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`